import React, {useState} from 'react';
import moment from 'moment';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faSquare, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import MatchStarComponent from './MatchStarComponent';

function MatchRow({ match, dateFormat, h2h, timezone, setIsDrawerOpen, setSelectedTeams, onStarUpdate, round, season, leagueId, setTabToOpen }) {
    const formattedTime = h2h ? moment(match.fixture.date).format(dateFormat) : moment(match.fixture.date).tz(timezone).format(dateFormat);
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);
    const [matchEvents, setMatchEvents] = useState({});

    const finishedStatuses = ['FT', 'AET', 'PEN', 'ABD', 'HT'];
    const inPlayStatuses = ['1H', '2H', 'ET', 'P', 'BT', 'PEN', 'LIVE'];
    const canceledStatuses = ['PST', 'CANC', 'ABD']
    const notStartedStatuses = ['TBD', 'NS', 'AWD', 'WO'];

    const getWinnerClass = (winner, matchStatus) => {
        if (!finishedStatuses.includes(matchStatus) || matchStatus === 'HT') {
            return '';
        }

        if (winner === null) {
            return 'draw';
        }

        return winner ? 'winner' : 'loser';
    };

    const handleHeadToHeadClick = (e, homeTeam, awayTeam) => {
        e.stopPropagation();
        setIsDrawerOpen(false);
        setSelectedTeams({ 
            home: homeTeam,
            away: awayTeam,
            round: round,
            season: season,
            leagueId: leagueId,
            homeName: homeName,
            awayName: awayName
        });
        setTabToOpen('h2h');
        setIsDrawerOpen(true);
    };

    const getEventIcon = (event) => {
        if (event.type === 'Goal') {
            return <FontAwesomeIcon icon={faFutbol} className={`goal-icon ${event.detail.split(" ").join('-').toLowerCase().trim()}`} />;
        } else if (event.type === 'Card') {
            if (event.detail === 'Yellow Card') {
                return <FontAwesomeIcon icon={faSquare} className="yellow-card-icon" style={{ color: 'yellow' }} />;
            } else if (event.detail === 'Red Card') {
                return <FontAwesomeIcon icon={faSquare} className="red-card-icon" style={{ color: 'red' }} />;
            } else if (event.detail === 'Second Yellow card') {
                return <FontAwesomeIcon icon={faSquare} className="second-yellow-card-icon" style={{ color: 'orange' }} />;
            }
        } else if (event.type === 'subst') {
            return <FontAwesomeIcon icon={faSyncAlt} className="substitution-icon" />;
        } else if (event.type === 'Var') {
            if (event.detail.includes('Goal')) {
                return <FontAwesomeIcon icon={faFutbol} className={`goal-icon var-goal-disallowed`} />;
            }
        }
        return event.type;
    };


    const homeWinner = match.teams.home.winner;
    const homeName = match.teams.home.name;
    const awayWinner = match.teams.away.winner;
    const awayName = match.teams.away.name;
    const homeHTScore = match.score.halftime.home;
    const awayHTScore = match.score.halftime.away;
    const penaltyHome = match.score.penalty.home;
    const penaltyAway = match.score.penalty.away;
    let matchStatus = match.fixture.status.short;
    const matchStatusLong = match.fixture.status.long;
    const elapsed = match.fixture.status.elapsed;
    const goalsHome = match.goals.home;
    const goalsAway = match.goals.away;
    const homeTeam = match.teams.home.id;
    const awayTeam = match.teams.away.id;

    const halfTimeDraw = homeHTScore === awayHTScore;
    const homeHTWinner = halfTimeDraw ? null : homeHTScore > awayHTScore;
    const awayHTWinner = halfTimeDraw ? null : awayHTScore > homeHTScore;

    const isOngoing = !finishedStatuses.includes(matchStatus) && inPlayStatuses.includes(matchStatus);

    if (matchStatus === 'P') {
        matchStatus = 'Penalties';
    }

    if (matchStatus === 'BT') {
        matchStatus = 'Break';
    }

    const toggleMatchDetails = (matchId) => {
        if (notStartedStatuses.includes(matchStatus) || canceledStatuses.includes(matchStatus)) {
            return;
        }

        if (isDetailsVisible) {
            // Close the details if they're already open
            setIsDetailsVisible(false);
        } else {
            // Fetch match events before opening the details
            axios.get(`https://v3.football.api-sports.io/fixtures/events?fixture=${matchId}`, {
                headers: {
                    'x-rapidapi-key': process.env.REACT_APP_API_FOOTBALL_KEY
                }
            })
                .then(response => {
                    if (response && response.data && response.data.response) {
                        setMatchEvents(response.data.response);
                        setIsDetailsVisible(true);
                    }
                })
                .catch(error => {
                    console.error('Error fetching match events:', error);
                });
        }
    };

    return (
        <>
            <div
                className={`match-row ${canceledStatuses.includes(matchStatus) ? 'canceled' : ''}`}
                onClick={() => toggleMatchDetails(match.fixture.id)}
            >
                <span className={h2h ? "match-date" : ""}>
                    {
                        !h2h && (
                            <MatchStarComponent
                                match={match}
                                onStarUpdate={onStarUpdate}
                            />
                        )
                    }
                    {formattedTime}
                </span>
                <span className={`${getWinnerClass(homeWinner, matchStatus)} team-name`}>
                    {homeName}
                </span>
                <span
                    onClick={h2h ? () => {
                    } : (e) => handleHeadToHeadClick(e, homeTeam, awayTeam)}
                    tabIndex="0"
                    className="main-score"
                >
                    <span>
                        <span className={`${getWinnerClass(homeWinner, matchStatus)}`}>
                        {goalsHome === null ? "–" : goalsHome}
                        </span>
                        :
                        <span className={`${getWinnerClass(awayWinner, matchStatus)}`}>
                            {goalsAway === null ? "–" : goalsAway}
                        </span>
                    </span>
                    {penaltyHome !== null && penaltyAway !== null && (
                        <p className="penalty-score">
                            (<label
                            className={`${getWinnerClass(homeWinner, matchStatus)}`}>{penaltyHome}</label> : <label
                            className={`${getWinnerClass(awayWinner, matchStatus)}`}>{penaltyAway}</label>)
                        </p>
                    )}
                </span>
                <span className={`${getWinnerClass(awayWinner, matchStatus)} team-name`}>
                    {awayName}
                </span>
                <span>
                <span className={`${getWinnerClass(homeHTWinner, matchStatus)}`}>
                    {homeHTScore === null ? "–" : homeHTScore}
                </span>
                :
                <span className={`${getWinnerClass(awayHTWinner, matchStatus)}`}>
                    {awayHTScore === null ? "–" : awayHTScore}
                </span>
                </span>
                <span className={`${isOngoing ? 'ongoing' : ''} ${matchStatus === "HT" ? 'ht-status' : ''}`}>
                    {(!inPlayStatuses.includes(matchStatus) || matchStatusLong === 'Match Finished' || matchStatus === 'P' || matchStatus === 'BT') ? matchStatus : ''} {elapsed && !finishedStatuses.includes(matchStatus) && !h2h ? `${elapsed}'` : ''}
                </span>
            </div>
            {isDetailsVisible && (
                <div className="match-details">
                    {matchEvents && matchEvents.length > 0 ? (
                        <ul>
                            {matchEvents.map((event, index) => (
                                <li key={index} className={`${event.team.name === homeName ? 'home-event' : 'away-event'}`}>
                                    {event.team.name === homeName ? getEventIcon(event) : ''} {event.team.name === homeName ? `${event.time.elapsed}'` : ''} {event.player.name} {`${event.type === 'subst' ? `(${event.assist.name})` : ((event.type === 'Goal' && event.detail !== 'Normal Goal') || event.type === 'Var' ? `(${event.detail})` : '')}`} {event.team.name === awayName ? `${event.time.elapsed}'` : ''} {event.team.name === awayName ? getEventIcon(event) : ''}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No events available for this match.</p>
                    )}
                </div>
            )}
        </>
    );
}

export default MatchRow;
